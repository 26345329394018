<template>
  <div class="content">
    <div class="List-title">
      <div class="titleLine">
        <span :class="open=='01'?'blueColor':''" @click="setOpen('01')">自驾</span>
        <span :class="open=='02'?'blueColor':''" @click="setOpen('02')">火车</span>
        <span :class="open=='03'?'blueColor':''" @click="setOpen('03')">客车</span>
        <span :class="open=='04'?'blueColor':''" @click="setOpen('04')">飞机</span>
        <span :class="open=='05'?'blueColor':''" @click="setOpen('05')">省内公交</span>
      </div>
    </div>
    <div style="height:1.6rem"></div>
    <div>
      <div class="tabArea" v-for="item in dataList" :key="item.name">
        <!-- <div class="tabIcon">{{item.content.trafficTime}}小时</div> -->
        <div class="tabTitle">
          <p class="titleFont">{{item.content.trafficName}}</p>
        </div>
        <div style="height:0.16rem"></div>
        <div class="contentFont">路线：{{item.content.trafficDetail}}</div>
      </div>
      <div style="height:0.4rem"></div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  name: 'traffic',

  data() {
    return {
      open: '01',
      dataList: []
    };
  },
  watch: {},
  created() {
    this.getRecords();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const open = this_.open;
      const topicSymbol = this_.getTopicSymbol(open);
      this_.$api.getContentByPage({ topicSymbol: topicSymbol }).then(res => {
        if (res.data.code === 10000) {
          this_.dataList = res.data.data.records;
        } else {
          Notify({ type: 'warning', message: res.data.msg });
        }
      });
    },
    setOpen(num) {
      this.open = num;
      this.getRecords();
    },
    getTopicSymbol(open) {
      let symbol = '';
      switch (open) {
        case '01':
          symbol = 'min-traffic-driving';
          break;
        case '02':
          symbol = 'min-traffic-train';
          break;
        case '03':
          symbol = 'min-traffic-remote-bus';
          break;
        case '04':
          symbol = 'min-traffic-air ';
          break;
        case '05':
          symbol = 'min-traffic-bus';
          break;
        default:
          symbol = 'min-traffic-driving';
      }
      return symbol;
    }
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.List-title {
  width: 100%;
  height: 1.12rem;
  border-bottom: 0.01rem solid #c7c7c7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: #fff;
}

.List-title span {
  width: 2rem;
  height: 1.12rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 1.12rem;
  float: left;
  text-align: center;
}
.blueColor {
  color: #00a5ff !important;
  box-sizing: border-box;
  border-bottom: #00a5ff 0.06rem solid;
}
.titleLine {
  height: 100%;
  width: 100%;
  position: relative;
}
.tabArea {
  height: 1.87rem;
  margin: 0.24rem 0.32rem 0rem 0.32rem;
  padding: 0.43rem 0.5rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
  position: relative;
  overflow-y: auto;
}

.tabTitle {
  height: 0.56rem;
  display: flex;
  align-items: center;
}

.titleFont {
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.contentFont {
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: left;
  color: rgba(136, 136, 136, 1);
}

.tabIcon {
  position: absolute;
  right: 0rem;
  top: 0rem;
  width: 1.57rem;
  height: 0.68rem;
  border-radius: 0rem 0.213rem 0rem 0.053rem;
  background-color: #00a5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}
</style>



